function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { customAlphabet } from 'nanoid';
import lodash from 'lodash';
export var nanoid = function nanoid() {
  return customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 1)() + customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 20)();
};
var mergeLine = function mergeLine(allLine) {
  for (var i in allLine.x) {
    allLine.x[i].sort(function (a, b) {
      return a.start - b.start;
    });
  }
  for (var _i in allLine.y) {
    allLine.y[_i].sort(function (a, b) {
      return a.start - b.start;
    });
  }
  for (var _i2 in allLine.x) {
    var item = allLine.x[_i2];
    for (var j = 0; j < item.length - 1;) {
      if (item[j + 1].start == item[j].end) {
        item.splice(j, 2, {
          start: item[j].start,
          end: item[j + 1].end
        });
      } else {
        j++;
      }
    }
  }
  for (var _i3 in allLine.y) {
    var _item = allLine.y[_i3];
    for (var _j = 0; _j < _item.length - 1;) {
      if (_item[_j + 1].start == _item[_j].end) {
        _item.splice(_j, 2, {
          start: _item[_j].start,
          end: _item[_j + 1].end
        });
      } else {
        _j++;
      }
    }
  }
};
export var makeLines = function makeLines(grids) {
  var allLine = {
    x: {},
    y: {}
  };
  if (grids.length < 2) {
    return null;
  }
  for (var i = 0; i < grids.length - 1; i++) {
    for (var j = i + 1; j < grids.length; j++) {
      // i在j上边
      if (grids[i][2] == grids[j][0] && grids[i][3] > grids[j][1] && grids[j][3] > grids[i][1]) {
        if (allLine.x[grids[i][2]]) {
          allLine.x[grids[i][2]].push({
            start: Math.max(grids[i][1], grids[j][1]),
            end: Math.min(grids[i][3], grids[j][3])
          });
        } else {
          allLine.x[grids[i][2]] = [{
            start: Math.max(grids[i][1], grids[j][1]),
            end: Math.min(grids[i][3], grids[j][3])
          }];
        }
      }
      // i在j下边
      if (grids[i][0] == grids[j][2] && grids[i][3] > grids[j][1] && grids[j][3] > grids[i][1]) {
        if (allLine.x[grids[i][0]]) {
          allLine.x[grids[i][0]].push({
            start: Math.max(grids[i][1], grids[j][1]),
            end: Math.min(grids[i][3], grids[j][3])
          });
        } else {
          allLine.x[grids[i][0]] = [{
            start: Math.max(grids[i][1], grids[j][1]),
            end: Math.min(grids[i][3], grids[j][3])
          }];
        }
      }
      // i在j左边
      if (grids[i][3] == grids[j][1] && grids[i][2] > grids[j][0] && grids[j][2] > grids[i][0]) {
        if (allLine.y[grids[i][3]]) {
          allLine.y[grids[i][3]].push({
            start: Math.max(grids[i][0], grids[j][0]),
            end: Math.min(grids[i][2], grids[j][2])
          });
        } else {
          allLine.y[grids[i][3]] = [{
            start: Math.max(grids[i][0], grids[j][0]),
            end: Math.min(grids[i][2], grids[j][2])
          }];
        }
      }
      // i在j右边
      if (grids[i][1] == grids[j][3] && grids[i][2] > grids[j][0] && grids[j][2] > grids[i][0]) {
        if (allLine.y[grids[i][1]]) {
          allLine.y[grids[i][1]].push({
            start: Math.max(grids[i][0], grids[j][0]),
            end: Math.min(grids[i][2], grids[j][2])
          });
        } else {
          allLine.y[grids[i][1]] = [{
            start: Math.max(grids[i][0], grids[j][0]),
            end: Math.min(grids[i][2], grids[j][2])
          }];
        }
      }
    }
  }
  mergeLine(allLine);
  return allLine;
};
// 如果xy有完全重复，就合并这一行列
var reduceMap = function reduceMap(gridMap, gridSizeMap) {
  var checkStatus = {
    xneedcheck: true,
    yneedcheck: true
  };
  var checkY = function checkY() {
    var change = false;
    if (gridMap.length > 1) {
      for (var i = 1; i < gridMap.length;) {
        if (lodash.isEqual(gridMap[i], gridMap[i - 1])) {
          gridMap.splice(i, 1);
          gridSizeMap.y.splice(i, 1);
          change = true;
        } else {
          i++;
        }
      }
    }
    checkStatus.yneedcheck = false;
    if (change == true) {
      checkStatus.xneedcheck = true;
    }
    if (checkStatus.xneedcheck) {
      checkX();
    }
  };
  var checkX = function checkX() {
    var change = false;
    if (gridMap[0].length > 1) {
      for (var i = 1; i < gridMap[0].length;) {
        var same = true;
        for (var j = 0; j < gridMap.length; j++) {
          if (gridMap[j][i] != gridMap[j][i - 1]) {
            same = false;
            break;
          }
        }
        if (same) {
          for (var _j2 = 0; _j2 < gridMap.length; _j2++) {
            gridMap[_j2].splice(i, 1);
          }
          gridSizeMap.x.splice(i - 1, 2, gridSizeMap.x[i - 1] + gridSizeMap.x[i]);
          change = true;
        } else {
          i++;
        }
      }
    }
    checkStatus.xneedcheck = false;
    if (change == true) {
      checkStatus.yneedcheck = true;
    }
    if (checkStatus.yneedcheck) {
      checkY();
    }
  };
  checkY();
  return gridMap;
};
// 根据所有单元格grid生成坐标map
export var makeGridMap = function makeGridMap(arr) {
  var arrInfo = arr.map(function (i) {
    return {
      id: i.com_id,
      grid: i.grid.split('/').map(Number)
    };
  });
  var heightNum = 0;
  var widthNum = 0;
  var _iterator = _createForOfIteratorHelper(arrInfo),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var i = _step.value;
      if (i.grid[2] - 1 > heightNum) {
        heightNum = i.grid[2] - 1;
      }
      if (i.grid[3] - 1 > widthNum) {
        widthNum = i.grid[3] - 1;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var gridMap = Array.from({
    length: heightNum
  }, function () {
    return new Array(widthNum);
  });
  var _iterator2 = _createForOfIteratorHelper(arrInfo),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var item = _step2.value;
      for (var x = item.grid[1]; x < item.grid[3]; x++) {
        for (var y = item.grid[0]; y < item.grid[2]; y++) {
          gridMap[y - 1][x - 1] = item.id;
        }
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  return gridMap;
  // console.log(arrInfo);
};
//根据坐标map生成所有单元格grid信息
export var makeGridInfo = function makeGridInfo(map, gridSizeMap) {
  map = reduceMap(map, gridSizeMap);
  var newGridInfoData = {};
  for (var y = 0; y < map.length; y++) {
    for (var x = 0; x < map[0].length; x++) {
      if (!newGridInfoData[map[y][x]]) {
        newGridInfoData[map[y][x]] = [y + 1, x + 1, y + 2, x + 2];
      } else {
        if (x + 2 > newGridInfoData[map[y][x]][3]) {
          newGridInfoData[map[y][x]][3] = x + 2;
        }
        if (y + 2 > newGridInfoData[map[y][x]][2]) {
          newGridInfoData[map[y][x]][2] = y + 2;
        }
      }
    }
  }
  return newGridInfoData;
};
//根据所有单元格grid信息重新排列所有单元格grid
// 不知道为什么会导致reducer反复执行，先不使用
export var rearrangeGrid = function rearrangeGrid(database, gridInfo) {
  for (var i in gridInfo) {
    database[i].grid = gridInfo[i].join('/');
  }
};
export default null;