export const gridMap = {
    x: [100],
    y: [480],
};
export const includeData = [
    {
        grid: '1/1/2/2',
    },
];

export const dataSizeJson = {
    1: {
        data: [
            {
                grid: '1/1/2/2',
            },
            {
                grid: '1/2/2/3',
            },
        ],
        map: {
            x: [50, 50],
            y: [480],
        },
    },
    2: {
        data: [
            {
                grid: '1/1/2/2',
            },
            {
                grid: '2/1/3/2',
            },
        ],
        map: {
            x: [100],
            y: [240, 240],
        },
    },
    3: {
        data: [
            {
                grid: '1/1/2/2',
            },
            {
                grid: '1/2/2/3',
            },
            {
                grid: '1/3/2/4',
            },
        ],
        map: {
            x: [33, 33, 34],
            y: [480],
        },
    },
    4: {
        data: [
            {
                grid: '1/1/2/2',
            },
            {
                grid: '2/1/3/2',
            },
            {
                grid: '3/1/4/2',
            },
        ],
        map: {
            x: [100],
            y: [160, 160, 160],
        },
    },
    5: {
        data: [
            {
                grid: '1/1/3/2',
            },
            {
                grid: '1/2/2/3',
            },
            {
                grid: '2/2/3/3',
            },
        ],
        map: {
            x: [50, 50],
            y: [240, 240],
        },
    },
    6: {
        data: [
            {
                grid: '1/1/2/2',
            },
            {
                grid: '2/1/3/2',
            },
            {
                grid: '1/2/2/3',
            },
            {
                grid: '2/2/3/3',
            },
        ],
        map: {
            x: [50, 50],
            y: [240, 240],
        },
    },
    7: {
        data: [
            {
                grid: '1/1/2/2',
            },
            {
                grid: '2/1/4/2',
            },
            {
                grid: '1/2/3/3',
            },
            {
                grid: '3/2/4/3',
            },
        ],
        map: {
            x: [50, 50],
            y: [160, 160, 160],
        },
    },
    8: {
        data: [
            {
                grid: '1/1/4/2',
            },
            {
                grid: '1/2/2/3',
            },
            {
                grid: '2/2/3/3',
            },
            {
                grid: '3/2/4/3',
            },
        ],
        map: {
            x: [50, 50],
            y: [160, 160, 160],
        },
    },
    9: {
        data: [
            {
                grid: '1/1/3/2',
            },
            {
                grid: '1/2/2/3',
            },
            {
                grid: '2/2/3/3',
            },
            {
                grid: '3/1/4/3',
            },
        ],
        map: {
            x: [50, 50],
            y: [160, 160, 160],
        },
    },
};

export default null;
