import React from 'react';
import { Link } from 'react-router-dom';
export default () => {
    return (
        <>
            <div>
                <Link to={''}>home</Link>
            </div>
            <div>404</div>
        </>
    );
};
